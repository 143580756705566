.cs-banner {
  height: 50px;
  z-index: 3;
  width: 100%;
  background-color: #a6d5d5;
  border: 2px solid #000;
  position: fixed;
}

/*# sourceMappingURL=index.css.map */
